import React, { useEffect, useState } from 'react';
import { useRoutes, Navigate, useLocation } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import './i18n';

import { ThemeProvider } from './contexts/ThemeContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { LayoutProvider } from './contexts/LayoutContext';
import ChartJsDefaults from './utils/ChartJsDefaults';
import { FormProvider } from './contexts/InvoiceformContext'; // Auth

import { SelectedProvider } from './contexts/SelectedTab';
import Homepage from './pages/pages/Homepage/Homepage';
import AddBooking from './pages/pages/AddBooking/AddBooking';
import Loader from './pages/pages/Components/Loader';
import { useUser } from './contexts/UserContext';
import ErrorScreen from './pages/pages/Components/ErrorScreen';
const App = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const { user, setUser } = useUser();
    let routes = [
        {
            path: '/',
            element: <Homepage user={user} />,
        },
        // {
        //   path: "/",
        //   element: <Navigate replace to="/book" />,
        // },
        {
            path: '/book',
            element: <AddBooking />,
        },
        {
            path: '*',
            element: <Navigate replace to='/' />,
        },
    ];

    async function getData(subdomain) {
        // const res = await fetch(
        //     `https://bestmate.us/portal/profile/inova?websiteDetails=true`
        const res = await fetch(
            `https://bestmate.us/portal/profile/${subdomain}?websiteDetails=true`
        );

        if (!res.ok) {
            setLoading(false);
            // throw new Error("Failed to fetch data");
        }
        let data = await res.json();
        setUser(data);
        setLoading(false);
    }

    useEffect(() => {
        const fullHostname = window.location.hostname;
        const hostnameParts = fullHostname.split('.');
        getData(hostnameParts[0]);
    }, []);

    const content = useRoutes(routes);
    return (
        <HelmetProvider>
            <Helmet
                // titleTemplate="%s | BestMate: The Next Generation Business App"
                // defaultTitle={`${
                //   user && user.business && user.business.business_name
                //     ? user.business.business_name
                //     : user.business.business_user_name
                //     ? user.business.business_user_name
                //     : "Bestmate"
                // }`}
                title={`${
                    user && user.business && user.business.business_name
                        ? user.business.business_name
                        : user &&
                          user.business &&
                          user.business.business_user_name
                        ? user.business.business_user_name
                        : 'Bestmate'
                }`}
            />
            <SelectedProvider>
                <FormProvider>
                    <ThemeProvider>
                        <SidebarProvider>
                            <LayoutProvider>
                                <ChartJsDefaults />
                                {/* {user && user.length !== 0 ? content : <Loader />} */}
                                {loading ? (
                                    <Loader />
                                ) : user && user.length !== 0 ? (
                                    content
                                ) : (
                                    <ErrorScreen />
                                )}
                            </LayoutProvider>
                        </SidebarProvider>
                    </ThemeProvider>
                </FormProvider>
            </SelectedProvider>
        </HelmetProvider>
    );
};

export default App;
